.Memory {
    height: 100%;
    text-align: center;
}

.Memory .mainDiv {
    position: absolute;
    width: 75%;
    top: 0;
    left: 12.5%;
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 3rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    color: #202020;
}

.Memory .mainDiv .textImg {
    margin: auto;
}

.Memory .mainDiv .subHeader {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1.5rem;
    font-weight: 500;
}

@media (max-width: 811px) {
    .Memory .mainDiv .textImg {
        width: 75%;
    }

    .Memory .mainDiv .memoryContainer {
        width: 100%;
    }
}

@media (min-width: 811px) {
    .Memory .mainDiv .textImg {
        width: 25%;
    }

    .Memory .mainDiv .memoryContainer {
        width: 50%;
    }
}

.Memory .mainDiv .memoryContainer {
    margin: auto;
    margin-bottom: 1rem;
    text-align: left;
}

.Memory .mainDiv .memoryContainer .sectionHeader {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1.25rem;
    font-weight: 900;
}

.Memory .mainDiv .memoryContainer .section {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1rem;
    font-weight: 400;
}

.Memory .mainDiv .questions {
    margin-bottom: 1rem;

    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1rem;
    font-weight: 600;
}

.Memory .mainDiv .questions.bottom {
    margin-bottom: 2rem;
}

.Memory .mainDiv .logoImg {
    width: 3rem;
    margin: auto;
    margin-bottom: 4rem;
}