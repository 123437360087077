.medal-block {
    width: 100%;
    height: 10rem;
    border-radius: 1rem;
    margin-bottom: 1rem;

    background-color: #202020;
}

.medal-block .medal-header {
    display: flex;
    flex-direction: row;

    font-family: montserrat, sans-serif;
    font-size: 1.25rem;
    font-weight: 700;

    margin-top: 0.5rem;
    margin-left: 0.5rem;

    color: #FFFFFF;
}

.medal-block .medalContainer {
    width: 100%;
    height: 7.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
}

.medal-block .medal {
    height: 5.5rem;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    border: none;
    aspect-ratio: 1/1;
    
    background-color: transparent;
    background-image: url('../assets/Medals/complete.png');
    background-repeat: no-repeat;
    background-size: contain;

    cursor: pointer;
}

.medal-block .medal.disabled {
    background-image: url('../assets/Medals/completeBW.png');
}

.medal-block .medal .lockImg {
    width: 65%;
}

/* -------------------------------------------------------------- */

.medal-block .medal.complete {
    background-image: url('../assets/Medals/complete.png');
}

.medal-block .medal.complete.disabled {
    background-image: url('../assets/Medals/completeBW.png');
}

.medal-block .medal.strength {
    background-image: url('../assets/Medals/strength.png');
}

.medal-block .medal.strength.disabled {
    background-image: url('../assets/Medals/strengthBW.png');
}

.medal-block .medal.offer {
    background-image: url('../assets/Medals/offer.png');
}

.medal-block .medal.offer.disabled {
    background-image: url('../assets/Medals/offerBW.png');
}

.medal-block .medal.rest {
    background-image: url('../assets/Medals/rest.png');
}

.medal-block .medal.rest.disabled {
    background-image: url('../assets/Medals/restBW.png');
}

.medal-block .medal.eat {
    background-image: url('../assets/Medals/eat.png');
}

.medal-block .medal.eat.disabled {
    background-image: url('../assets/Medals/eatBW.png');
}

.medal-block .medal.week1 {
    background-image: url('../assets/Medals/week1.png');
}

.medal-block .medal.week1.disabled {
    background-image: url('../assets/Medals/week1BW.png');
}

.medal-block .medal.week2 {
    background-image: url('../assets/Medals/week2.png');
}

.medal-block .medal.week2.disabled {
    background-image: url('../assets/Medals/week2BW.png');
}

.medal-block .medal.week3 {
    background-image: url('../assets/Medals/week3.png');
}

.medal-block .medal.week3.disabled {
    background-image: url('../assets/Medals/week3BW.png');
}

.medal-block .medal.week4 {
    background-image: url('../assets/Medals/week4.png');
}

.medal-block .medal.week4.disabled {
    background-image: url('../assets/Medals/week4BW.png');
}

.medal-block .medal.week5 {
    background-image: url('../assets/Medals/week5.png');
}

.medal-block .medal.week5.disabled {
    background-image: url('../assets/Medals/week5BW.png');
}

.medal-block .medal.week6 {
    background-image: url('../assets/Medals/week6.png');
}

.medal-block .medal.week6.disabled {
    background-image: url('../assets/Medals/week6BW.png');
}

.medal-block .medal.week7 {
    background-image: url('../assets/Medals/week7.png');
}

.medal-block .medal.week7.disabled {
    background-image: url('../assets/Medals/week7BW.png');
}

.medal-block .medal.perfect {
    background-image: url('../assets/Medals/perfect.png');
}

.medal-block .medal.perfect.disabled {
    background-image: url('../assets/Medals/perfectBW.png');
}

/* -------------------------------------------------------------- */

@media (max-width: 640px) {
    .popupContainer {
        width: 85vw;
        height: 85vw;
    }

    .popupContainer .aniDiv .medalContainer .closeButton {
        width: 2.5rem;
        height: 2.5rem;
        transform: translate(-3.5rem, 0.75rem);
    }

    .popupContainer .aniDiv .medalContainer .closeButton {
        background-size: 1.25rem;
    }
  }

@media (min-width: 640px) {
    .popupContainer {
        width: 75vw;
        height: 75vw;
    }

    .popupContainer .aniDiv .medalContainer .closeButton {
        width: 2.5rem;
        height: 2.5rem;
        transform: translate(-3.5rem, 0.75rem);
    }

    .popupContainer .aniDiv .medalContainer .closeButton {
        background-size: 1.25rem;
    }
  }

@media (min-width: 768px) {
    .popupContainer {
        width: 65vw;
        height: 65vw;
    }

    .popupContainer .aniDiv .medalContainer .closeButton {
        width: 2.5rem;
        height: 2.5rem;
        transform: translate(-3.5rem, 0.75rem);
    }

    .popupContainer .aniDiv .medalContainer .closeButton {
        background-size: 1.25rem;
    }
}

@media (min-width: 1024px) {
    .popupContainer {
        width: 55vw;
        height: 55vw;
    }

    .popupContainer .aniDiv .medalContainer .closeButton {
        width: 3rem;
        height: 3rem;
        transform: translate(-4rem, 1rem);
    }

    .popupContainer .aniDiv .medalContainer .closeButton {
        background-size: 1.5rem;
    }
}

@media (min-width: 1280px) {
    .popupContainer {
        width: 45vw;
        height: 45vw;
    }

    .popupContainer .aniDiv .medalContainer .closeButton {
        width: 3rem;
        height: 3rem;
        transform: translate(-4rem, 1rem);
    }

    .popupContainer .aniDiv .medalContainer .closeButton {
        background-size: 1.5rem;
    }
}

@media (min-width: 1536px) {
    .popupContainer {
        width: 35vw;
        height: 35vw;
    }

    .popupContainer .aniDiv .medalContainer .closeButton {
        width: 3rem;
        height: 3rem;
        transform: translate(-4rem, 1rem);
    }

    .popupContainer .aniDiv .medalContainer .closeButton {
        background-size: 1.5rem;
    }
}
  
@media (min-width: 2560px) {
    .popupContainer {
        max-width: 25vw;
        max-height: 25vw;
    }

    .popupContainer .aniDiv .medalContainer .closeButton {
        width: 3rem;
        height: 3rem;
        transform: translate(-4rem, 1rem);
    }

    .popupContainer .aniDiv .medalContainer .closeButton {
        background-size: 1.5rem;
    }
}

.popupContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}

.popupContainer .aniDiv {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    animation: loadani 0.25s ease-out;
}

@keyframes loadani {
    0% {
        transform: rotateY(90deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}

.popupContainer .aniDiv .popupBlock {
    position: absolute;
    top: 0;
    left: 0;
}

.popupContainer .aniDiv .medalContainer {
    width: 100%;
    height: 100%;
    border-radius: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
    background-color: #202020;
}

.popupContainer .aniDiv .medalContainer .closeButton {
    position: absolute;
    top: 0%;
    left: 100%;
    border: none;
    border-radius: 50%;

    background-image: url('../assets/Icons/close.png');
    background-position: center;
    background-repeat: no-repeat;

    background-color: #E0E0E0;

    cursor: pointer;

    z-index: 2;
}

.popupContainer .aniDiv .medalContainer .arScene {
    width: 100%;
    height: 100%;
}

.popupContainer .aniDiv .medalContainer .arScene.disabled {
    filter: grayscale();
    opacity: 0.25;
}

.popupContainer .aniDiv .medalContainer .arScene.gold {
    filter: saturate(0.6);
}

.popupContainer .aniDiv .medalContainer .arScene.disabled.gold {
    filter: saturate(0.6);
    filter: grayscale();
}

.popupContainer .aniDiv .medalContainer .arScene.perfect {
    filter: saturate(0.9);
}

.popupContainer .aniDiv .medalContainer .arScene.disabled.perfect {
    filter: saturate(0.9);
    filter: grayscale();
}

.popupContainer .aniDiv .medalContainer .medalInfo {
    margin-bottom: 2rem;
}

.popupContainer .aniDiv .medalContainer .medalInfo .title {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1.5rem;
    font-weight: 700;

    color: #FFFFFF;
}

.popupContainer .aniDiv .medalContainer .medalInfo .description {
    margin-left: 1rem;
    margin-right: 1rem;

    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1rem;
    font-weight: 400;

    color: #FFFFFF;
}