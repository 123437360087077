.announcement-block {
    width: 100%;
    min-height: 5rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
    padding: 1rem 0.5rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    background-color: #E0E0E0;
}

.announcement-block .announcement-text {
    font-family: montserrat, sans-serif;
    font-size: 1.25rem;
    font-weight: 700;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    margin-right: 1rem;

    color: #202020;
}

.announcement-block .announcement-button {
    width: 15rem;
    height: 3rem;
    border: none;
    border-radius: 1rem;
    margin-right: 1rem;
    background-color: #FF671F;

    font-family: montserrat, sans-serif;
    font-size: 1.25rem;
    font-weight: 700;
    color: #FFFFFF;
    letter-spacing: 0.025rem;
    text-transform: uppercase;

    cursor: pointer;
}