@media (max-width: 640px) {
    .team-block {
        max-width: 27.5rem;
        flex-wrap: wrap;
    }
    
    .team-block .teamLeaderboard {
        width: 100%;
        height: fit-content;
    }

    .team-block .listBlock {
        width: 100%;
    }
}

@media (min-width: 640px) {
    .team-block .teamLeaderboard {
        width: 100%;
    }

    .team-block .listBlock {
        width: 48%;
    }
}

.team-block {
    height: fit-content;
    border-radius: 1rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.team-block .teamLeaderboard {
    border-radius: 1rem;
    margin-bottom: 1rem;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    background-color: #E0E0E0;
}

.team-block .sectionHeader {
    display: flex;
    flex-direction: row;
    width: 100%;

    font-family: montserrat, sans-serif;
    font-size: 1.25rem;
    font-weight: 700;

    margin-top: 0.5rem;
    margin-left: 0.5rem;

    color: #202020;
}

.team-block .waiting {
    font-family: montserrat, sans-serif;
    font-size: 1rem;
    font-weight: 500;
}

/* -------------------------------------------------------------- */

.team-block .listBlock {
    height: 3rem;
    margin-right: 1rem;
    margin-bottom: 1rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.team-block .listBlock.team {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.team-block .listBlock.team:disabled {
    cursor: default;
}

.team-block .listBlock .section {
    display: flex;
    flex-direction: row;
}

.team-block .listBlock .section .verticalText {
    margin-left: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;

    text-align: left;
}

.team-block .listBlock .section .verticalText .displayName {
    margin: 0;
    margin-right: auto;

    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 0.85rem;
    font-weight: 600;

    color: #000000;
}

.team-block .listBlock .section .verticalText .pointsAndMeps {
    margin: 0;
    margin-right: auto;

    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 0.75rem;
    font-weight: 600;

    color: #000000;
    opacity: 0.5;
}

.team-block .listBlock .placementBG {
    width: 5rem;
    height: 2rem;
    border-radius: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #585858;
}

.team-block .listBlock .placementBG.first {
    background-color: #E5C97F;
}

.team-block .listBlock .placementBG.second {
    background-color: #9FABB9;
}

.team-block .listBlock .placementBG.third {
    background-color: #AC9380;
}

.team-block .listBlock .placementBG .placementText {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1rem;
    font-weight: 700;
    
    color: #FFFFFF;
}

/* ---------------------------------------------------------------- */

@media (max-width: 640px) {
    .teamSelected-block {
        width: 95vw;
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        width: 2.5rem;
        height: 2.5rem;
        transform: translate(-3.5rem, 0.75rem);
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        background-size: 1.25rem;
    }
  }

@media (min-width: 640px) {
    .teamSelected-block {
        width: 65vw;
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        width: 2.5rem;
        height: 2.5rem;
        transform: translate(-3.5rem, 0.75rem);
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        background-size: 1.25rem;
    }
  }

@media (min-width: 768px) {
    .teamSelected-block {
        width: 45vw;
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        width: 2.5rem;
        height: 2.5rem;
        transform: translate(-3.5rem, 0.75rem);
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        background-size: 1.25rem;
    }
}

@media (min-width: 1024px) {
    .teamSelected-block {
        width: 45vw;
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        width: 3rem;
        height: 3rem;
        transform: translate(-4rem, 1rem);
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        background-size: 1.5rem;
    }
}

@media (min-width: 1280px) {
    .teamSelected-block {
        width: 45vw;
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        width: 3rem;
        height: 3rem;
        transform: translate(-4rem, 1rem);
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        background-size: 1.5rem;
    }
}

@media (min-width: 1536px) {
    .teamSelected-block {
        width: 45vw;
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        width: 3rem;
        height: 3rem;
        transform: translate(-4rem, 1rem);
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        background-size: 1.5rem;
    }
}
  
@media (min-width: 2560px) {
    .teamSelected-block {
        max-width: 45vw;
        max-height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        width: 3rem;
        height: 3rem;
        transform: translate(-4rem, 1rem);
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        background-size: 1.5rem;
    }
}

.teamSelected-block {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}

.teamSelected-block .aniDiv {
    position: absolute;
    width: 100%;
    height: fit-content;
    top: 0;
    left: 0;

    animation: loadani 0.25s ease-out;
}

@keyframes loadani {
    0% {
        transform: rotateY(90deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}

.teamSelected-block .aniDiv .popupBlock {
    position: absolute;
    top: 0;
    left: 0;
}

.teamSelected-block .aniDiv .teamContainer {
    width: 100%;
    height: fit-content;
    border-radius: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    background-color: #E8E3CD;
}

.teamSelected-block .aniDiv .teamContainer .closeButton {
    position: absolute;
    top: 0%;
    left: 100%;
    border: none;
    border-radius: 50%;

    background-image: url('../assets/Icons/close.png');
    background-position: center;
    background-repeat: no-repeat;

    background-color: #C1B08F;

    cursor: pointer;

    z-index: 2;
}

.teamSelected-block .aniDiv .teamContainer .teamHeader {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
    margin-right: auto;
    margin-left: 1rem;

    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1.5rem;
    font-weight: 700;
}

.teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
    width: 100%;
    overflow-y: scroll;
}

.teamSelected-block .aniDiv .teamContainer .teamInfoContainer .teamEntry {
    width: 95%;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.teamSelected-block .aniDiv .teamContainer .teamInfoContainer .teamEntry .memberInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.teamSelected-block .aniDiv .teamContainer .teamInfoContainer .teamEntry .memberInfo .memberName {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1.15rem;
    font-weight: 600;
    margin: 0;
    margin-right: 0.5rem;
}

.teamSelected-block .aniDiv .teamContainer .teamInfoContainer .teamEntry .memberInfo .memberRole {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1.15rem;
    font-weight: 500;
    margin: 0;
    color: #707070;
}

.teamSelected-block .aniDiv .teamContainer .teamInfoContainer .teamEntry .memberPoints {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1.15rem;
    font-weight: 600;
    margin: 0;
}