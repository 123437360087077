.Auth {
  text-align: center;
  overflow: hidden;
}

.Auth .bgIMG {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;

  background-image: url('../assets/BKGRND.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.Auth .mainDiv {
  position: absolute;
  width: 50%;
  height: 100vh;

  margin-top: -3%;
  margin-left: 24.5%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Auth .loader {
  position: absolute;
  top: 0;
  left: 0;
}

.Auth .header {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.subtext {
  font-family: montserrat, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  margin: 0;
  margin-bottom: 1rem;

  color: #231F20;
}

.Auth .headerImg {
  width: 100%;
  margin: auto;
  margin-bottom: 1rem;
}

.Auth .headertext {
  font-family: montserrat, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  margin: 0;

  color: #202020;
}

.Auth .buttons * {
  box-sizing: border-box;
}

.Auth .buttons {
  display: flex;
  flex-direction: column;
}

.Auth .button {
  width: 22.5rem;
  height: 3rem;
  margin: auto;
  border: none;
  border-radius: 1rem;
}

.Auth .button.g {
  margin-bottom: 1rem;
  background-color: #FF671F;

  cursor: pointer;
}

.Auth .button.p {
  margin-bottom: 0.5rem;
  background-color: #E0E0E0;

  cursor: pointer;
}

.Auth .button.r {
  background-color: transparent;

  cursor: pointer;
}

.Auth .button.base {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 0.5rem;
}

.Auth .google.img {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}

.Auth .google.text {
  font-family: montserrat, sans-serif;
  font-style: normal;
  font-size: 1.35rem;
  font-weight: 600;
  color: #FFFFFF;

  margin-top: 0;
  margin-bottom: 0;
}

.Auth .errorGText {
  font-family: montserrat, sans-serif;
  font-style: normal;
  font-size: 1rem;
  font-weight: 400;
  color: #231F20;

  margin-top: 0;
  margin-bottom: 1rem;
}

.Auth .phone.img {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}

.Auth .phone.text {
  font-family: montserrat, sans-serif;
  font-style: normal;
  font-size: 1.35rem;
  font-weight: 600;
  color: #707070;

  margin-top: 0;
  margin-bottom: 0;
}

.Auth .rules.text {
  font-family: montserrat, sans-serif;
  font-style: normal;
  font-size: 1rem;
  font-weight: 400;
  color: #FFFFFF;

  margin-top: 0;
  margin-bottom: 0;
}

/* ------------------------------------------------------ */

.Auth .inputText {
  width: 20.5rem;
  height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;

  border: none;
  border-radius: 1rem;
  background-color: #FFFFFF;
  
  font-family: montserrat, sans-serif;
  font-style: normal;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  color: #202020;

  text-align: center;
}

.Auth .cancelAndSubmit {
  width: 22.5rem;
  height: 3rem;
  margin: auto;
  margin-top: 1rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Auth .cancelButton {
  width: 3rem;
  height: 3rem;
  border: none;
  border-radius: 3rem;
  color: #E0E0E0;
  
  margin-right: 1rem;

  cursor: pointer;
}

.Auth .cancelImg {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0;
  margin-top: 15%;
}

.Auth .submitButton {
  width: 18.5rem;
  height: 3rem;
  border: none;
  border-radius: 1rem;

  font-family: montserrat, sans-serif;
  font-style: normal;
  font-size: 1.5rem;
  font-weight: 600;

  color: #FFFFFF;
  background-color: #FF671F;

  cursor: pointer;
}

.Auth .sentToText {
  font-family: montserrat, sans-serif;
  font-style: normal;
  font-size: 1rem;
  font-weight: 400;
  color: #202020;
}

.Auth .requestAgainButton {
  width: 22.5rem;
  margin-top: 1rem;
  border: none;
  background-color: transparent;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.Auth .requestMain {
  font-family: montserrat, sans-serif;
  font-style: normal;
  font-size: 1rem;
  font-weight: 400;
  color: #202020;
}

.Auth .requestSub {
  margin-left: 1rem;

  font-family: montserrat, sans-serif;
  font-style: normal;
  font-size: 1rem;
  font-weight: 600;
  color: #202020;
}