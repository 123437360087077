.Test {
    text-align: center;
  }
  
  .Test .mainDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .Test .header {
    width: 100%;
    padding-bottom: 0.5vh;
    margin-bottom: 2vh;
    border-bottom: 1px solid #E0E0E0;

    display: flex;
    justify-content: center;
  }

  .Test .header .spacer {
    width: 3rem;
    margin-left: 0.5rem;
  }

  .Test .header .logo {
    background-image: url('../assets/Logo/Logo@3x.png');
    transition: background-image 350ms ease;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 3rem;
    height: 3rem;

    margin-left: auto;
    margin-right: auto;

    cursor: pointer;
  }

  .Test .header .logo:hover {
    background-image: url('../assets/Logo/LogoDark@3x.png');
    transition: background-image 350ms ease;
  }

  .Test .header .profile {
    width: 3rem;
    height: 3rem;
    border: none;
    border-radius: 50%;
    margin-right: 0.5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #E0E0E0;
    transition: background-color 350ms ease;

    cursor: pointer;
  }

  .Test .header .profile:link {
    background-color: #E0E0E0;
    transition: background-color 350ms ease;

    cursor: pointer;
  }

  .Test .header .profile .profileImg {
    width: 1.5rem;
    height: 1.5rem;

    cursor: pointer;
  }

  .Test .header .profile .profileImg2 {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;

    cursor: pointer;
  }

  /* .Test .mainColumn {
    width: 50vw;

    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
  } */

  /* .Test .dualDiv {
    display: flex;
    flex-direction: row;
  }

  .Test .block {
    border-radius: 35px;
    margin: 1vw;
    background-color: #E0E0E0;
  }

  .Test .block.big {
    width: 100%;
    height: 25vh;
  } */

  @media (min-width: 640px) {
    .column {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .column {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .column {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .column {
      max-width: 1280px;
    }
  }

  @media (min-width: 1536px) {
    .column {
      max-width: 1536px;
    }
  }

  .Test .column {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  /* --- STUFF --- */

  .Test .helloDate {
    max-width: 56rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;

    display: flex;
    flex-direction: column;
    text-align: start;
  }

  .Test .helloDate .date {
    font-family: montserrat, sans-serif;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;

    color: #707070;
  }

  .Test .helloDate .hello {
    font-family: montserrat, sans-serif;
    font-size: 2.5rem;
    font-weight: 800;
    margin: 0;

    color: #202020;
  }

  .Test .content {
    max-width: 56rem;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: column;
  }

  .Test .rulesText {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1rem;
    font-weight: 400;
    color: #202020;
    text-decoration: none;
  
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;

    cursor: pointer;
  }