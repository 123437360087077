@media (max-width: 640px) {
    .topRow-block {
        max-width: 27.5rem;
        flex-wrap: wrap;
    }
}

.topRow-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.topRow-block .logContainer {
    width: 27.5rem;
    height: 22.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.topRow-block .logSection {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 18.5rem;
    border-radius: 1rem;
    background-color: #E0E0E0;
}

.topRow-block .headerArea {
    height: 2.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.topRow-block .logContainer .logSection .headerArea .logHeader {
    font-family: montserrat, sans-serif;
    font-size: 1.25rem;
    font-weight: 700;
    margin-left: 0.5rem;

    color: #202020;
}

.topRow-block .logContainer .logSection .headerArea .daySelection {
    height: 2.25rem;
    margin-right: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: none;
    border-radius: 1rem;

    font-family: montserrat, sans-serif;
    font-size: 1rem;
    font-weight: 600;

    color: #202020;
}

.topRow-block .logContainer .logSection .headerArea .daySelection:focus {
    border-color: #FF671F;
}

.topRow-block .section-header {
    display: flex;
    flex-direction: row;

    font-family: montserrat, sans-serif;
    font-size: 1.25rem;
    font-weight: 700;

    margin-top: 0.5rem;
    margin-left: 0.5rem;

    color: #202020;
}

.topRow-block .logSection-elements {
    width: 100%;
    height: fit-content;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.topRow-block .elementButton {
    width: 20%;
    background-color: transparent;
    border: none;
}

.topRow-block .elementDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.topRow-block .elementDot {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 3rem;
    height: 3rem;
    border: none;
    border-radius: 50%;
    background-color: #FFFFFF;

    cursor: pointer;
}

.topRow-block .elementDot.selected {
    background-color: #FF671F;
}

.topRow-block .elementImg {
    width: 2rem;
    height: 2rem;
}

.topRow-block .elementHeader {
    font-family: montserrat, sans-serif;
    font-size: 0.75rem;
    font-weight: 600;

    margin: 0.5rem;

    color: #202020;
}

.topRow-block .messageInput {
    width: 95%;
    height: 5rem;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 0;
    border: none;
    border-radius: 1rem;

    box-sizing: border-box;
    padding: 0.5rem;

    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 0.75rem;
    font-weight: 500;
    text-align: left;

    word-wrap: break-word;
    word-break: break-all;
    overflow-wrap: break-word;
    overflow: hidden;
    resize: none;
}

.topRow-block .messageInput:focus {
    border-color: #FF671F;
}

.topRow-block .extrasArea {
    width: 95%;
    height: 2rem;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.topRow-block .extrasArea .mepsArea {
    display: flex;
    flex-direction: row;
}

.topRow-block .extrasArea .mepsArea .mepsHeader {
    margin-right: 0.5rem;
    font-family: montserrat, sans-serif;
    font-size: 1rem;
    font-weight: 600;
}

.topRow-block .extrasArea .mepsArea .mepsInput {
    width: 5rem;
    height: 2.25rem;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: none;
    border-radius: 1rem;

    font-family: montserrat, sans-serif;
    font-size: 1rem;
    font-weight: 600;

    color: #202020;
}

.topRow-block .extrasArea .remainingCharacters {
    margin-top: auto;
    margin-left: auto;
    margin-right: 0.5rem;
    font-family: montserrat, sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
}

.topRow-block .logButton {
    width: 100%;
    height: 3rem;
    border: none;
    border-radius: 1rem;
    background-color: #FF671F;

    cursor: pointer;
}

.topRow-block .logButton-header {
    font-family: montserrat, sans-serif;
    font-size: 2rem;
    font-weight: 700;

    margin: 0;

    color: #FFFFFF;
}

/* ----------------------------------------------------- */

@media (max-width: 640px) {
    .topRow-block .progressContainer {
        height: fit-content;
        padding-top: 0.5rem;
        padding-bottom: 1rem;
    }
}

@media (min-width: 640px) {
    .topRow-block .progressContainer {
        height: 22.5rem;
    }
}

.topRow-block .progressContainer {
    width: 27.5rem;
    border-radius: 1rem;
    margin-bottom: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    background-color: #E0E0E0;
}

.topRow-block .progressBG {
    width: 97%;
    height: 1.5rem;
    margin: auto;
    border-radius: 500px;

    background-color: #707070;
}

.topRow-block .progressIndividual {
    width: 0%;
    height: 1.5rem;
    border-radius: 500px;

    background-color: #FF671F;
}

.topRow-block .progressTeam {
    width: 0%;
    height: 1.5rem;
    border-radius: 500px;

    background-color: #FF671F;
}

.topRow-block .section-header .teamLink {
    margin-left: 0.4rem;
    text-decoration: underline;
    cursor: pointer;
}

.topRow-block .progressContainer .pointSection {
    width: 95%;
    height: 8rem;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1rem;

    background-color: #FFFFFF;
}

.topRow-block .progressContainer .pointSection .newHeader {
    display: flex;
    flex-direction: row;

    font-family: montserrat, sans-serif;
    font-size: 1.25rem;
    font-weight: 700;

    margin-top: 0.5rem;
    margin-left: 0.5rem;

    color: #202020;
}

.topRow-block .progressContainer .pointSection .pointsList {
    width: 100%;
    height: fit-content;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.topRow-block .progressContainer .pointSection .pointsList .listItemVertical {
    width: 15%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.topRow-block .progressContainer .pointSection .pointsList .listItemVertical .itemPoint {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
    margin-bottom: 0.5rem;
}

.topRow-block .progressContainer .pointSection .pointsList .listItemVertical .itemHeader {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 0.75rem;
    font-weight: 500;
    margin: 0;
}

/* ---------------------------------------------------------------- */

@media (max-width: 640px) {
    .teamSelected-block {
        width: 95vw;
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        width: 2.5rem;
        height: 2.5rem;
        transform: translate(-3.5rem, 0.75rem);
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        background-size: 1.25rem;
    }
  }

@media (min-width: 640px) {
    .teamSelected-block {
        width: 65vw;
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        width: 2.5rem;
        height: 2.5rem;
        transform: translate(-3.5rem, 0.75rem);
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        background-size: 1.25rem;
    }
  }

@media (min-width: 768px) {
    .teamSelected-block {
        width: 45vw;
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        width: 2.5rem;
        height: 2.5rem;
        transform: translate(-3.5rem, 0.75rem);
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        background-size: 1.25rem;
    }
}

@media (min-width: 1024px) {
    .teamSelected-block {
        width: 45vw;
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        width: 3rem;
        height: 3rem;
        transform: translate(-4rem, 1rem);
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        background-size: 1.5rem;
    }
}

@media (min-width: 1280px) {
    .teamSelected-block {
        width: 45vw;
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        width: 3rem;
        height: 3rem;
        transform: translate(-4rem, 1rem);
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        background-size: 1.5rem;
    }
}

@media (min-width: 1536px) {
    .teamSelected-block {
        width: 45vw;
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        width: 3rem;
        height: 3rem;
        transform: translate(-4rem, 1rem);
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        background-size: 1.5rem;
    }
}
  
@media (min-width: 2560px) {
    .teamSelected-block {
        max-width: 45vw;
        max-height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        width: 3rem;
        height: 3rem;
        transform: translate(-4rem, 1rem);
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        background-size: 1.5rem;
    }
}

.teamSelected-block {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}

.teamSelected-block .aniDiv {
    position: absolute;
    width: 100%;
    height: fit-content;
    top: 0;
    left: 0;

    animation: loadani 0.25s ease-out;
}

@keyframes loadani {
    0% {
        transform: rotateY(90deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}

.teamSelected-block .aniDiv .popupBlock {
    position: absolute;
    top: 0;
    left: 0;
}

.teamSelected-block .aniDiv .teamContainer {
    width: 100%;
    height: fit-content;
    border-radius: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    background-color: #E8E3CD;
}

.teamSelected-block .aniDiv .teamContainer .closeButton {
    position: absolute;
    top: 0%;
    left: 100%;
    border: none;
    border-radius: 50%;

    background-image: url('../assets/Icons/close.png');
    background-position: center;
    background-repeat: no-repeat;

    background-color: #C1B08F;

    cursor: pointer;

    z-index: 2;
}

.teamSelected-block .aniDiv .teamContainer .teamHeader {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
    margin-right: auto;
    margin-left: 1rem;

    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1.5rem;
    font-weight: 700;
}

.teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
    width: 100%;
    overflow-y: scroll;
}

.teamSelected-block .aniDiv .teamContainer .teamInfoContainer .teamEntry {
    width: 95%;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.teamSelected-block .aniDiv .teamContainer .teamInfoContainer .teamEntry .memberInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.teamSelected-block .aniDiv .teamContainer .teamInfoContainer .teamEntry .memberInfo .memberName {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1.15rem;
    font-weight: 600;
    margin: 0;
    margin-right: 0.5rem;
}

.teamSelected-block .aniDiv .teamContainer .teamInfoContainer .teamEntry .memberInfo .memberRole {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1.15rem;
    font-weight: 500;
    margin: 0;
    color: #707070;
}

.teamSelected-block .aniDiv .teamContainer .teamInfoContainer .teamEntry .memberPoints {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1.15rem;
    font-weight: 600;
    margin: 0;
}