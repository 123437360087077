@media (max-width: 640px) {
    .individual-block {
        max-width: 27.5rem;
        flex-wrap: wrap;
    }

    .individual-block .individualLeaderboard {
        width: 100%;
        height: fit-content;
    }

    .individual-block .listBlock {
        width: 100%;
    }
}

@media (min-width: 640px) {
    .individual-block .individualLeaderboard {
        width: 100%;
    }

    .individual-block .listBlock {
        width: 48%;
    }
}

.individual-block {
    height: fit-content;
    border-radius: 1rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.individual-block .individualLeaderboard {
    border-radius: 1rem;
    margin-bottom: 1rem;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    background-color: #E0E0E0;
}

.individual-block .sectionHeader {
    display: flex;
    flex-direction: row;
    width: 100%;

    font-family: montserrat, sans-serif;
    font-size: 1.25rem;
    font-weight: 700;

    margin-top: 0.5rem;
    margin-left: 0.5rem;

    color: #202020;
}

.individual-block .waiting {
    font-family: montserrat, sans-serif;
    font-size: 1rem;
    font-weight: 500;
}

/* -------------------------------------------------------------- */

.individual-block .listBlock {
    height: 3rem;
    margin-bottom: 1rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.individual-block .listBlock.team {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.individual-block .listBlock.team:disabled {
    cursor: default;
}

.individual-block .listBlock .section {
    display: flex;
    flex-direction: row;
}

.individual-block .listBlock .section .profilePic {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin-left: 0.5rem;
}

.individual-block .listBlock .section .noProfilePic {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin-left: 0.5rem;
    
    background-color: #FF671F;
    background-image: url('../assets/Icons/person\(white\).png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.25rem;
}

.individual-block .listBlock .section .verticalText {
    margin-left: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;

    text-align: left;
}

.individual-block .listBlock .section .verticalText .displayName {
    margin: 0;
    margin-right: auto;

    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 0.85rem;
    font-weight: 600;

    color: #000000;
}

.individual-block .listBlock .section .verticalText .pointsAndMeps {
    margin: 0;
    margin-right: auto;

    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 0.75rem;
    font-weight: 600;

    color: #000000;
    opacity: 0.5;
}

.individual-block .listBlock .placementBG {
    width: 25%;
    height: 2rem;
    border-radius: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #585858;
}

.individual-block .listBlock .placementBG.first {
    background-color: #E5C97F;
}

.individual-block .listBlock .placementBG.second {
    background-color: #9FABB9;
}

.individual-block .listBlock .placementBG.third {
    background-color: #AC9380;
}

.individual-block .listBlock .placementBG .placementText {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1rem;
    font-weight: 700;
    
    color: #FFFFFF;
}