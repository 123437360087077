.Register {
    text-align: center;
    overflow: hidden;
}

@media (max-width: 640px) {
    .Register .bgIMG {
        transform: scale(1.2);
    }

    .Register .mainDiv .infoForm .label {
        width: 70vw;
    }

    .Register .mainDiv .infoForm .input {
        font-size: 1rem;
    }

    .Register .mainDiv .infoForm .spacer {
        width: 70vw;
        height: 0;
    }

    .Register .mainDiv .infoForm .submitButton {
        width: 70vw;
        margin: auto;

        font-size: 1rem;
    }
}

@media (min-width: 640px) {
    .Register .mainDiv .infoForm .label {
        width: 34vw;
    }

    .Register .mainDiv .infoForm .input {
        font-size: 1.25rem;
    }

    .Register .mainDiv .infoForm .spacer {
        width: 34vw;
        height: 3rem;
    }

    .Register .mainDiv .infoForm .submitButton {
        width: 34vw;
        margin: auto;
        margin-top: 1.5rem;

        font-size: 1.5rem;
    }
}

.Register .bgIMG {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0;

    background-image: url('../assets/BKGRND.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.Register .mainDiv {
    position: absolute;
    width: 100vw;
    top: 0;
    left: 0;
    margin: 0;
    margin-top: 10%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Register .mainDiv .infoForm * {
    box-sizing: border-box;
}

.Register .mainDiv .infoForm {
    max-width: 70vw;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.Register .mainDiv .infoForm .label {
    margin-bottom: 0.5rem;

    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1rem;
    font-weight: 400;

    color: #202020;
    text-align: left;
}

.Register .mainDiv .infoForm .input {
    width: 100%;
    height: 3rem;
    margin-bottom: 1rem;
    margin-left: 0;
    margin-right: 0;
    border: none;
    border-radius: 1rem;

    font-family: montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    padding: 0;
    padding-left: 1rem;

    color: #202020;
    background-color: #E0E0E0;
}

.Register .mainDiv .infoForm .input:disabled {
    color: #202020;
    background-color: #E0E0E0;
    opacity: 50%;
}

.Register .mainDiv .infoForm .input:focus {
    outline-color: #FF671F;
}

.Register .mainDiv .infoForm .input:invalid {
    outline-color: red;
}

.Register .mainDiv .infoForm .spacer {
    margin-bottom: 1rem;
    margin-left: 0;
    margin-right: 0;
    border: none;

    background-color: transparent;
}

.Register .mainDiv .infoForm .submitButton {
    height: 3rem;
    border: none;
    border-radius: 1rem;

    font-family: montserrat, sans-serif;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;

    color: #FFFFFF;
    background-color: #FF671F;

    cursor: pointer;
}

.Register .mainDiv .ticketButton {
    margin-top: 2rem;
    border: none;
    
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1rem;
    font-weight: 400;
    text-decoration: none;

    color: #FFFFFF;
    background-color: transparent;

    cursor: pointer;
}