.profile-block {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    margin-bottom: 5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: #E0E0E0;
}

.profile-block .header {
    display: flex;
    flex-direction: row;
    justify-content: left;

    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1.25rem;
    font-weight: 700;

    margin-top: 0.75rem;
    margin-left: 1rem;

    color: #202020;
}

@media (max-width: 640px) {
    .profile-block .mainDiv .infoForm .input {
        font-size: 1rem;
    }

    .profile-block .mainDiv .infoForm .submitButton {
        width: 100%;
    }
}

@media (min-width: 640px) {
    .profile-block .mainDiv .infoForm .input {
        font-size: 1.25rem;
    }

    .profile-block .mainDiv .infoForm .submitButton {
        width: 50%;
    }
}

.profile-block .mainDiv .infoForm * {
    box-sizing: border-box;
}

.profile-block .mainDiv .infoForm {
    max-width: 90%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.profile-block .mainDiv .infoForm .label {
    font-family: montserrat, sans-serif;
    font-style: normal;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;

    color: #202020;
    text-align: left;
}

.profile-block .mainDiv .infoForm .input {
    width: 100%;
    height: 3rem;
    margin-bottom: 1rem;
    margin-left: 0;
    margin-right: 0;
    border: none;
    border-radius: 1rem;

    font-family: montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    padding: 0;
    padding-left: 1rem;

    color: #202020;
    background-color: #FFFFFF;
}

.profile-block .mainDiv .infoForm .input:disabled {
    color: #202020;
    background-color: #FFFFFF;
    opacity: 50%;
}

.profile-block .mainDiv .infoForm .input:focus {
    outline-color: #FF671F;
}

.profile-block .mainDiv .infoForm .input:invalid {
    outline-color: red;
}

.profile-block .mainDiv .infoForm .sizingChartImg {
    width: 15rem;
    height: 17.5rem;
}

.profile-block .mainDiv .infoForm .spacer {
    width: 100%;
    height: 0.25rem;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;

    background-color: #202020;
    opacity: 0.5;
}

.profile-block .mainDiv .infoForm .spacerBottom {
    width: 15rem;
}

.profile-block .mainDiv .infoForm .submitButton {
    height: 3rem;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 2rem;
    border: none;
    border-radius: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: uppercase;

    color: #FFFFFF;
    background-color: #FF671F;

    cursor: pointer;
}

/* -------------------------------------------------------------- */

.profile-logOutButton {
    width: 75%;
    height: 3rem;
    margin: auto;
    border: none;
    border-radius: 1rem;

    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;

    color: #202020;
    background-color: #E0E0E0;
}