.header-block {
    width: 100%;
    height: 12.5rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
}

.header-block .headerImg {
    width: 100%;
    height: 12.5rem;
    border-radius: 1rem;

    object-fit: cover;
    object-position: 0 -2rem;
}

.header-block .textImg {
    width: 100%;
    height: 12.5rem;

    object-fit: contain;
    position: absolute;
    left: 0;
    scale: 0.65;
}