.activity-block {
    width: 100%;
    height: fit-content;
    border-radius: 1rem;
    margin-bottom: 1rem;

    background-color: #E0E0E0;
}

.activity-block .activity-header {
    display: flex;
    flex-direction: row;

    font-family: montserrat, sans-serif;
    font-size: 1.25rem;
    font-weight: 700;

    margin: 0;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 1.5rem;

    color: #202020;
}

.activity-block .activityContainer {
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding-left: 1rem;
    padding-right: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.activity-block .activityContainer .entry {
    width: 100%;
    height: fit-content;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: #202020 solid 1px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.activity-block .activityContainer .entry .entryTopRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
}

.activity-block .activityContainer .entry .entryTopRow .userInfo {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    text-align: left;
}

.activity-block .activityContainer .entry .entryTopRow .userInfo .displayName {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
    margin-right: 0.5rem;
}

.activity-block .activityContainer .entry .entryTopRow .userInfo .teamName {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    text-decoration: underline;
    cursor: pointer;

    color: #707070;
}

.activity-block .activityContainer .entry .entryTopRow .dateTime {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
}

.activity-block .activityContainer .entry .entryMessage {
    width: 100%;
    margin: 0;
    margin-bottom: 0.75rem;

    font-family: montserrat;
    font-style: normal;
    font-size: 0.75rem;
    font-weight: 400;
    text-align: left;
    word-wrap: break-word;
    white-space:pre-line;
}

.activity-block .activityContainer .entry .entryBottomRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.activity-block .activityContainer .entry .entryBottomRow .items {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.activity-block .activityContainer .entry .entryBottomRow .items .strength {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
}

.activity-block .activityContainer .entry .entryBottomRow .items .offer {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
}

.activity-block .activityContainer .entry .entryBottomRow .items .rest {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
}

.activity-block .activityContainer .entry .entryBottomRow .items .eat {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
}

.activity-block .activityContainer .entry .entryBottomRow .likeContainer {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.activity-block .activityContainer .entry .entryBottomRow .likeContainer .likeAmount {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
    margin-right: 0.25rem;

    color: #707070;
}

.activity-block .activityContainer .entry .entryBottomRow .likeContainer .likeButton {
    width: fit-content;
    height: fit-content;
    margin: 0;
    border: none;

    background-color: transparent;

    cursor: pointer;
}

.activity-block .activityContainer .entry .entryBottomRow .likeContainer .likeButton .likeImage {
    width: 1.5rem;
    height: 1.5rem;
    opacity: 0.5;

    background-image: url('../assets/Icons/hand.thumbsup.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.activity-block .activityContainer .entry .entryBottomRow .likeContainer .likeButton:hover .likeImage {
    background-image: url('../assets/Icons/hand.thumbsup.fill\(orange\).png');
    transition: background-image 100ms ease;
}

/* ---------------------------------------------------------------- */

@media (max-width: 640px) {
    .teamSelected-block {
        width: 95vw;
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        width: 2.5rem;
        height: 2.5rem;
        transform: translate(-3.5rem, 0.75rem);
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        background-size: 1.25rem;
    }
  }

@media (min-width: 640px) {
    .teamSelected-block {
        width: 65vw;
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        width: 2.5rem;
        height: 2.5rem;
        transform: translate(-3.5rem, 0.75rem);
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        background-size: 1.25rem;
    }
  }

@media (min-width: 768px) {
    .teamSelected-block {
        width: 45vw;
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        width: 2.5rem;
        height: 2.5rem;
        transform: translate(-3.5rem, 0.75rem);
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        background-size: 1.25rem;
    }
}

@media (min-width: 1024px) {
    .teamSelected-block {
        width: 45vw;
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        width: 3rem;
        height: 3rem;
        transform: translate(-4rem, 1rem);
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        background-size: 1.5rem;
    }
}

@media (min-width: 1280px) {
    .teamSelected-block {
        width: 45vw;
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        width: 3rem;
        height: 3rem;
        transform: translate(-4rem, 1rem);
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        background-size: 1.5rem;
    }
}

@media (min-width: 1536px) {
    .teamSelected-block {
        width: 45vw;
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        width: 3rem;
        height: 3rem;
        transform: translate(-4rem, 1rem);
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        background-size: 1.5rem;
    }
}
  
@media (min-width: 2560px) {
    .teamSelected-block {
        max-width: 45vw;
        max-height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
        height: 55vh;
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        width: 3rem;
        height: 3rem;
        transform: translate(-4rem, 1rem);
    }

    .teamSelected-block .aniDiv .teamContainer .closeButton {
        background-size: 1.5rem;
    }
}

.teamSelected-block {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.teamSelected-block .aniDiv {
    position: absolute;
    width: 100%;
    height: fit-content;
    top: 0;
    left: 0;

    animation: loadani 0.25s ease-out;
}

@keyframes loadani {
    0% {
        transform: rotateY(90deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}

.teamSelected-block .aniDiv .popupBlock {
    position: absolute;
    top: 0;
    left: 0;
}

.teamSelected-block .aniDiv .teamContainer {
    width: 100%;
    height: fit-content;
    border-radius: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    background-color: #E8E3CD;
}

.teamSelected-block .aniDiv .teamContainer .closeButton {
    position: absolute;
    top: 0%;
    left: 100%;
    border: none;
    border-radius: 50%;

    background-image: url('../assets/Icons/close.png');
    background-position: center;
    background-repeat: no-repeat;

    background-color: #C1B08F;

    cursor: pointer;

    z-index: 2;
}

.teamSelected-block .aniDiv .teamContainer .teamHeader {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
    margin-right: auto;
    margin-left: 1rem;

    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1.5rem;
    font-weight: 700;
}

.teamSelected-block .aniDiv .teamContainer .teamInfoContainer {
    width: 100%;
    overflow-y: scroll;
}

.teamSelected-block .aniDiv .teamContainer .teamInfoContainer .teamEntry {
    width: 95%;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.teamSelected-block .aniDiv .teamContainer .teamInfoContainer .teamEntry .memberInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.teamSelected-block .aniDiv .teamContainer .teamInfoContainer .teamEntry .memberInfo .memberName {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1.15rem;
    font-weight: 600;
    margin: 0;
    margin-right: 0.5rem;
}

.teamSelected-block .aniDiv .teamContainer .teamInfoContainer .teamEntry .memberInfo .memberRole {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1.15rem;
    font-weight: 500;
    margin: 0;
    color: #707070;
}

.teamSelected-block .aniDiv .teamContainer .teamInfoContainer .teamEntry .memberPoints {
    font-family: montserrat, sans-serif;
    font-style: normal;
    font-size: 1.15rem;
    font-weight: 600;
    margin: 0;
}